<template>
  <div class="home-page _flex">
    <!-- 485px drawer 组件宽度 width: calc(100% - 485px); -->
    <div class="home-page__left" :style="{ width: `calc(100% - ${isDrawer ? '485px' : '0px'})` }">
      <div class="home-page__header">
        <div class="home-page__header__title _font-weight__600 _font-size__heading1">
          Welcome back, {{ $store.getters.userInfo.nickName }}.
        </div>
        <div class="home-page__header__tabs _flex">
          <div
            v-for="(item, index) in tabs"
            :key="item.label"
            :class="[
              'home-page__header__tabs__item _font-weight__600 _font-size__heading3 _pointer',
              { active: index == tabsIndex }
            ]"
            @click="handleTabsChange(item, index)"
          >
            {{ item.label }}
          </div>

          <!-- 红色线 -->
          <div class="home-page__header__tabs__border" :style="borderStyle"></div>
        </div>
        <div class="home-page__header__operate _flex _flex-justify__between">
          <!-- 这里需要对元素宽度固定，否则进行动画时元素宽度会自适应 -->
          <div class="home-page__header__operate__form-box _flex">
            <el-input
              class="input-rem"
              v-model="searchForm.condition"
              :placeholder="tabsIndex == 0 ? 'Search Tower Name, e,g. KAM12' : 'Search Defect ID'"
            />
            <el-button type="info" @click="handleSearch"> Search </el-button>
            <el-select
              v-model="searchForm.selectValue"
              clearable
              :placeholder="tabsIndex == 0 ? 'All Locations' : 'All Severities'"
            >
              <el-option
                v-for="item in options[tabsIndex]"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              >
              </el-option>
            </el-select>
          </div>

          <!-- <Transition name="fade-opacity"> -->
          <div
            class="home-page__header__operate__buttons _flex"
            :style="{
              transform: `translateX(${isDrawer ? '-485px' : '0px'})`
            }"
            v-show="tabsIndex === 0"
          >
            <el-button
              :type="componentName === 'map-view' ? 'info' : 'default'"
              icon="el-icon-map-location"
              @click="handleSwitchComponent('map-view')"
            >
              Map View
            </el-button>
            <el-button
              :type="componentName === 'list-view' ? 'info' : 'default'"
              icon="el-icon-s-unfold"
              @click="handleSwitchComponent('list-view')"
            >
              List View
            </el-button>
          </div>
          <!-- </Transition> -->
        </div>
      </div>

      <!-- 组件区 -->
      <Transition name="slate" mode="out-in">
        <KeepAlive>
          <Component
            :is="componentName"
            ref="child"
            style="width: var(--main-width)"
            :list="componentName === 'alert-history' ? alertResult.rows : towerResult.rows"
            :total="
              componentName === 'alert-history' ? Number(alertResult.total) : towerResult.total
            "
            @tower-change="handleTowerChange"
            @get-list="handleGetList"
          ></Component>
        </KeepAlive>
      </Transition>
    </div>

    <Drawer
      v-model="isDrawer"
      :detail="towerDetail"
      :dateList="dateList"
      :isLoading="isLoading"
      @input="
        () => $refs.child.$refs.table && $refs.child.$refs.table.$refs.multipleTable.setCurrentRow()
      "
    ></Drawer>
  </div>
</template>

<script>
import { ROUTER_NAME } from '@/router'
import { optionsFun } from '@/utils'
import { findPage, alertFindPage, locationList, towerDefectDateList } from '@/api/home'
import common from '@/common'
import MapView from './components/map-view'

const defaultQuery = {
  selectValue: '',
  condition: '',
  orderBy: '',
  pageNo: 1,
  pageSize: 100,
  sort: ''
}
export default {
  name: 'home',
  components: {
    MapView,
    ListView: () => import('./components/list-view'),
    AlertHistory: () => import('./components/alert-history'),
    Drawer: () => import('./components/drawer')
  },
  data() {
    return {
      isLoading: false,
      componentName: 'map-view',
      // 电塔信息
      towerResult: {
        rows: [],
        total: 0
      },
      towerDetail: {},
      // AlertHistory
      alertResult: {
        rows: [],
        total: 0
      },

      options: [
        [],
        common.constData.severity.map((item) => {
          const copyItem = { ...item }
          if (copyItem.dictLabel === 'Critical') {
            copyItem.dictLabel = 'Major'
            copyItem.dictValue = 'Major'
          }

          return copyItem
        })
      ],

      // tabs切换
      domPosition: [],
      tabsIndex: 0,
      tabs: [
        {
          label: 'Project List',
          componentName: 'map-view'
        },
        {
          label: 'Alerts History',
          componentName: 'alert-history'
        }
      ],
      searchForm: { ...defaultQuery },

      isDrawer: false,
      dateList: []
    }
  },
  computed: {
    borderStyle() {
      if (this.domPosition.length === 0) return { width: '0px' }
      const style = {
        width: `${this.domPosition[this.tabsIndex].width}px`,
        transform: `translateX(${this.domPosition[this.tabsIndex].left}px)`
      }

      return style
    }
  },
  watch: {
    '$route.query': {
      handler(val) {
        if (val.tabsIndex && this.$route.name === ROUTER_NAME.HOME) {
          this.tabsIndex = val.tabsIndex
          this.componentName = this.tabs[val.tabsIndex].componentName
        }
      },
      immediate: true
    }
  },
  activated() {
    this.getAlertList()
  },
  mounted() {
    this.getList()
    locationList().then((res) => {
      this.options[0] = optionsFun(res.result)
    })
    this.$nextTick(() => {
      const doms = document.getElementsByClassName('home-page__header__tabs__item')
      for (let i = 0; i < doms.length; i += 1) {
        this.domPosition.push({
          width: doms[i].clientWidth,
          left: doms[i].offsetLeft
        })
      }
    })
  },
  methods: {
    getList() {
      this.isDrawer = false
      // 获取电塔
      findPage({ ...this.searchForm, location: this.searchForm.selectValue }).then((res) => {
        this.towerResult = res.result
      })
    },
    // 获取警告信息
    getAlertList() {
      alertFindPage({
        ...this.searchForm,
        condition: '',
        severity: this.searchForm.selectValue,
        defectId: this.searchForm.condition
      }).then((res) => {
        this.alertResult = res.result
      })
    },
    // 发起请求
    handleGetList(e) {
      Object.assign(this.searchForm, e)

      this.handleSearch()
    },
    handleSearch() {
      if (this.tabsIndex === 0) {
        this.getList()
        if (this.componentName === 'map-view')
          this.$refs.child.setVisible(this.searchForm.condition)
      } else {
        this.getAlertList()
      }
    },
    handleTabsChange(item, index) {
      Object.assign(this.searchForm, defaultQuery)
      this.tabsIndex = index
      this.componentName = item.componentName
      this.isDrawer = false
    },
    // map-view 和 list-view 切换
    handleSwitchComponent(component) {
      this.componentName = component
      // 取消选中
      if (
        component === 'map-view' &&
        this.$refs.child.$refs.table &&
        this.$refs.child.$refs.table.$refs.multipleTable
      ) {
        this.$refs.child.$refs.table.$refs.multipleTable.setCurrentRow()
      }
      this.isDrawer = false
    },
    handleTowerChange(detail) {
      if (detail) {
        this.towerDetail = detail
        this.isDrawer = true
        this.isLoading = true

        // 获取塔日期列表
        towerDefectDateList({ towerCode: detail.code }).then((res) => {
          this.dateList = res.result
          this.isLoading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  &__header {
    margin-bottom: 35px;
    &__title {
      margin-bottom: 20px;
    }
    &__tabs {
      position: relative;
      height: 35px;
      margin-bottom: 23px;

      &__item {
        transition: all 0.3s;
        &.active {
          color: var(--color-primary);
        }
      }

      &__item + .home-page__header__tabs__item {
        margin-left: 40px;
      }

      &__border {
        position: absolute;
        transition: all 0.3s ease;
        bottom: 0;
        left: 0;
        height: 3px;
        background: var(--color-primary);
      }
    }

    &__operate {
      width: var(--main-width);
      .input-rem {
        height: 36px;
        line-height: 36px;
      }

      &__form-box {
        & > * {
          margin-right: 20px;
          line-height: 36px;

          ::v-deep .el-input__inner {
            height: 100%;
          }
        }
        & > ::v-deep .el-input {
          width: 325px;
          .el-input__inner {
            border-color: #979797;
            color: var(--color-black);
            &::placeholder {
              color: #979797;
            }
          }
        }

        & .el-button {
          width: 70px;
          color: var(--color-white);
          text-align: center;
          padding: 0 !important;
        }

        & > ::v-deep .el-select {
          width: 160px;

          .el-input {
            height: 100%;
          }
          .el-input__inner {
            color: var(--color-black);
            border: none;
            border-bottom: 2px solid var(--color-info);
            border-radius: 0px;
            background: rgba(0, 0, 0, 0);
            padding: 0;
            &::placeholder {
              color: var(--color-black);
            }
          }
          & .el-select__caret.el-icon-arrow-up::before {
            content: '\e78f';
            color: var(--color-black);
          }

          .el-input__icon {
            line-height: 1;
          }
        }
      }

      &__buttons {
        transition: all 0.3s ease;

        .el-button {
          padding: 0px 15px;
        }
      }
    }
  }
}
</style>
